<template>
  <div>
    <div class="overview_tools">  
      <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showUnemployed" size="is-small" @input="getOrganisationStructure()">{{ $t('users.Show_unemployed') }}</b-switch>      
      <div class="mt-1 mt-xl-0 d-inline-block align-top">      
        <CButton class="ml-2" color="primary" @click="makeScreenshot()">
          <i class="fas fa-image mr-1"/><span>{{ $t('insights.Save_as_image') }}</span>
        </CButton>
      </div> 
      <hr>
    </div>
    <div>
      <CRow class="h-100">
        <CCol cols="12" xl="12" lg="12" md="12" sm="12" v-bind:class="{ 'pt-0 pb-0' : dataLoaded }">
          <loadingSpinner mode="auto" v-if="!dataLoaded" :content="$t('organisation.Loading_structure')"/>

          <div v-else class="pl-3 pr-3 pb-3 organization_chart">
            <organization-chart :datasource="organisationStructure" :pan="true" :zoom="true" :zoomout-limit="0.25" :zoomin-limit="2">
              <template slot-scope="{ nodeData }">
                <div class="node-title">
                  
                  <div v-if="nodeData.type === 'organization' && companyImageId" class="mb-1">
                    <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/' + companyIdExternal + '/' + companyImageId + '.png' + '/' + clientToken"/>
                  </div>

                  <div v-if="nodeData.type === 'department'">
                    <span>{{nodeData.department_name}}</span>
                  </div>
                  
                  <div v-if="nodeData.type === 'team'">
                    <span>{{nodeData.team_name}}</span>                   
                  </div>

                  <div v-if="nodeData.type === 'employee'">
                    <userProfilePopover ref="userProfilePopover"
                                        mode="user"
                                        :userName="nodeData.employee_name"
                                        :userProfileImage="nodeData.employee_profile_image"
                                        :userIdExternal="nodeData.employee_user_id_external"
                                        :senderIdExternal="null"
                                        popoverPlacement="right"
                                        class="mb-1"/>
                    <span>{{nodeData.employee_name}}</span>
                  </div>

                </div>
                <div class="node-content">
                  <div v-if="nodeData.type === 'organization' && nodeData.company_admin_user_id_external">
                    <span><b>{{nodeData.company_name}}</b></span>
                    <div class="mt-1 d-flex align-items-center justify-content-center managers">
                      <userProfilePopover ref="userProfilePopover"
                                          mode="user"
                                          :userName="nodeData.company_admin_name"
                                          :userProfileImage="nodeData.company_admin_profile_image"
                                          :userIdExternal="nodeData.company_admin_user_id_external"
                                          :senderIdExternal="null"
                                          popoverPlacement="right"/>
                    </div>
                  </div>

                  <div v-if="nodeData.type === 'department' && nodeData.department_managers.length > 0">
                    <span class="managed_by">{{$t('organisation.Managed_by')}}</span>
                    <div class="mt-1 d-flex align-items-center justify-content-center managers">
                      <userProfilePopover ref="userProfilePopover"
                                          v-for="manager in nodeData.department_managers"
                                          v-bind:key="manager.user_id_external"
                                          mode="user"
                                          :userName="manager.name"
                                          :userProfileImage="manager.profile_image"
                                          :userIdExternal="manager.user_id_external"
                                          :senderIdExternal="null"
                                          popoverPlacement="right"/>
                    </div>
                  </div>

                  <div v-if="nodeData.type === 'team' && nodeData.team_managers.length > 0">
                    <span class="managed_by">{{$t('organisation.Managed_by')}}</span>
                    <div class="mt-1 d-flex align-items-center justify-content-center managers">
                      <userProfilePopover ref="userProfilePopover"
                                          v-for="manager in nodeData.team_managers"
                                          v-bind:key="manager.user_id_external"
                                          mode="user"
                                          :userName="manager.name"
                                          :userProfileImage="manager.profile_image"
                                          :userIdExternal="manager.user_id_external"
                                          :senderIdExternal="null"
                                          popoverPlacement="right"/>
                    </div>
                  </div>
                </div>                
              </template>
            </organization-chart>
          </div>
        </CCol>
      </CRow>
    </div>
  </div>  
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import OrganizationChart from 'vue-organization-chart';
import 'vue-organization-chart/dist/orgchart.css';

export default {
  name: 'Structure',
  components: {
    loadingSpinner,
    userProfilePopover,
    OrganizationChart
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      companyIdExternal: null,
      companyImageId: null,      
      dataLoaded: false,
      organisationStructure: [],
      showUnemployed: false     
    }
  },
  methods: {
    getOrganisationStructure () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/structure')
      .then(res => {
        this.organisationStructure = res.data.data;
        this.dataLoaded = true;

        //Check if the inactive users should be shown
        if(!this.showUnemployed) {          
          for(var d = 0; d < this.organisationStructure.children.length; d++) {
            for(var t = 0; t < this.organisationStructure.children[d].children.length; t++) {               
              this.organisationStructure.children[d].children[t].children = this.organisationStructure.children[d].children[t].children.filter( i => i.employee_left === null || i.employee_left > new Date() );              
            }
          }  
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    makeScreenshot() {
      window.scroll(0,0);
      // Get the HTML to print
      const content = document.getElementsByClassName("orgchart")[0];
      // Set the html2canvas options
      const options = { type: "dataURL", useCORS: true };

      setTimeout(function() {
        // Print the screenshot
        this.printScreenshot(content, options);
      }.bind(this), 500);
    },
    async printScreenshot(content, options) {
      // Create canvas for printing
      const printCanvas = await html2canvas(content, options);
      // Creata a link element to download the generated canvas
      const link = document.createElement("a");
  
      link.setAttribute("download", "organization_structure_" + new Date().toLocaleString() + ".png");
      link.setAttribute("href", printCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
      link.click();
    }       
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');
    if(localStorage.getItem('companyImageId') !== null) this.companyImageId = localStorage.getItem('companyImageId');

    this.getOrganisationStructure();   
  }
}
</script>