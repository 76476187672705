<template>
  <CRow v-if="platformRolesLoaded && platformPermissionsLoaded && checkPermission('core.organization.overview')" class="organization">
    <CCol cols="12" xl="12" lg="12" md="12" sm="12">        
      <CCard class="mb-0 h-100">
        <CCardHeader class="pb-1">
          <CRow>
            <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">
              {{$t('core.Organization')}}
            </CCol>
            <CCol cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButtonGroup class="mr-1">
                  <CButton color="outline-secondary" v-for="(value, key) in ['departments', 'users']" :key="key" :pressed="value === activeView ? true : false" @click="activeView = value;">
                    <i v-if="value === 'departments'" class="fas fa-table"></i>
                    <i v-if="value === 'users'" class="fas fa-users"></i>
                    <!-- <i v-if="value === 'structure'" class="fas fa-sitemap"></i> -->
                  </CButton>
                </CButtonGroup>
              </div>
            </CCol>  
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <div v-if="activeView === 'departments'" class="h-100 d-flex flex-column">
            <Departments :platformRoles="platformRoles" :platformPermissions="platformPermissions" :seatsAvailable="seatsAvailable"/>
          </div>
          <div v-if="activeView === 'users'" class="list_view">
            <Users :platformRoles="platformRoles" :platformPermissions="platformPermissions" :seatsAvailable="seatsAvailable"/>
          </div>
          <!-- <div v-if="activeView === 'structure'" class="list_view">
            <Structure/>
          </div> -->
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

import Departments from '@/views/core/organisation/Departments.vue';
import Users from '@/views/core/organisation/Users.vue';
import Structure from '@/views/core/organisation/Structure.vue';

export default {  
  name: 'OrganizationOverview',
  components: {
    loadingSpinner,
    noPermission,
    Departments,
    Users,
    Structure
  },
  data() {
    return {
      platformRoles: [],
      platformRolesLoaded: false,
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      overviewDataLoaded: false,
      activeView: 'departments',
      seatsAvailable: false
    }
  },
  methods: {
    checkLicense() {
      // Get the license data
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/license/seatsAvailable')
      .then(res => {
        this.seatsAvailable = res.data.data.seats_available;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
        // Update the platformRolesLoaded value
        this.platformRolesLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }
  },
  mounted: function() {
    // Check if the organizationViewToActivate item is available in localStorage
    if(localStorage.getItem('organizationViewToActivate') !== null) {
      // Set the activeView according to the organizationViewToActivate
      this.activeView = localStorage.getItem('organizationViewToActivate');
      // Remove the organizationViewToActivate item from localStorage
      localStorage.removeItem('organizationViewToActivate');
    }
    
    this.getPlatformRoles();
    this.getPlatformPermissions();
    this.checkLicense();
  }
}
</script>