<template>
  <CCard class="notice_card mb-3">
    <CCardBody>       
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <span class="d-block mb-1"><b>{{$t('communications.Import_or_add_employees')}}</b></span>
          <span v-html="$t('communications.User_management_instructions')"></span>
        </div>
        <div class="align-self-start">
          <span class="pointer" @click="markInstructionsAsRead()"><i class="fas fa-times"/></span>
        </div>          
      </div>                
    </CCardBody>
  </CCard>
</template>

<script>

export default {
  name: 'UserManagementInstructions',  
  methods: {
    markInstructionsAsRead() {
      // Set userManagementInstructionsRead item in localStorage
      localStorage.setItem('userManagementInstructionsRead', 'Y');
      // Emit the user_management_instructions_read event 
      this.$bus.$emit('user_management_instructions_read');
    },
  }
}
</script>